// JokeDisplay.js
import React, { useState, useEffect } from 'react';
import WelcomeMessage from './WelcomeMessage';
import './App.css';

const JokeDisplay = () => {
    const [joke, setJoke] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('flachwitze');
    const [showJokes, setShowJokes] = useState(false);

    const categories = ['scherzfragen', 'chuck-norris-witze', 'antiwitze', 'flachwitze'];

    const fetchJoke = async () => {
        try {
            const response = await fetch(
                `https://witzapi.de/api/joke/?limit=1&category=${selectedCategory}&language=de`
            );

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            setJoke(data[0].text);
            setShowJokes(true);
        } catch (error) {
            console.error('Error fetching joke:', error);
        }
    };

    const calculateFontSize = () => {
        const baseFontSize = 3.2;
        const maxFontSize = 4.2;
        const minFontSize = 1.2;

        const fontSize = Math.max(minFontSize, Math.min(maxFontSize, baseFontSize - joke.length * 0.02));
        return `${fontSize}rem`;
    };

    useEffect(() => {
        fetchJoke();
    }, [selectedCategory]);

    return (
        <div
            style={{
                display: 'flex',
                color: 'white',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: '100vh',
                background: 'linear-gradient(to bottom, rgba(255, 0, 0, 0), rgba(255, 0, 0, 0.8)), linear-gradient(to right, rgba(0, 128, 0, 0), rgba(0, 128, 0, 0.8))',
            }}
        >
            <WelcomeMessage onDismiss={() => setShowJokes(true)} />
            {showJokes && (
                <>
                    <div
                        style={{
                            overflowY: 'auto',
                            minHeight: '30vh',
                            textAlign: 'center',
                            margin: '20px',
                            fontSize: calculateFontSize(),
                            color: 'black',
                        }}
                    >
                        {joke}
                    </div>
                    <div style={{ textAlign: 'center', height: '60px' }}>
                        <label htmlFor="categorySelector">Such dir eine Kategorie aus: </label>
                        <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
                            {categories.map((category) => (
                                <button
                                    key={category}
                                    style={{
                                        padding: '8px',
                                        margin: '5px',
                                        cursor: 'pointer',
                                        backgroundColor: selectedCategory === category ? '#e57373' : '#81c784', // Red and green for a Christmas theme
                                        border: 'none',
                                        borderRadius: '4px',
                                        transition: 'background-color 0.3s ease-in-out',
                                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                        outline: 'none',
                                        color: "black"
                                    }}
                                    onClick={() => setSelectedCategory(category)}
                                    onTouchStart={(e) => e.target.style.backgroundColor = '#ef9a9a'}
                                    onTouchEnd={(e) => e.target.style.backgroundColor = selectedCategory === category ? '#e57373' : '#81c784'}
                                >
                                    {category}
                                </button>
                            ))}
                        </div>
                        <button
                            onClick={fetchJoke}
                            style={{
                                marginTop: '10px',
                                padding: '10px',
                                cursor: 'pointer',
                                borderRadius: '4px',
                                backgroundColor: '#f0bb9b', // A warm color for a Christmas theme
                                transition: 'background-color 0.3s ease-in-out',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                outline: 'none',
                                color: "black"
                            }}
                            onTouchStart={(e) => e.target.style.backgroundColor = '#f7ccac'}
                            onTouchEnd={(e) => e.target.style.backgroundColor = '#f0bb9b'}
                        >
                            Neuer Witz
                        </button>
                    </div>
                </>
            )}
        </div>
    );
};

export default JokeDisplay;

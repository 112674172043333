// WelcomeMessage.js
import React, { useState } from 'react';
import backgroundImage from './background.jpg';

const WelcomeMessage = ({ onDismiss }) => {
    const [isVisible, setIsVisible] = useState(true);
    const [buttonClicked, setButtonClicked] = useState(false);

    const handleDismiss = () => {
        setIsVisible(false);
        setButtonClicked(true);
        onDismiss();
    };

    return (
        <>
            {/* Always displayed message at the bottom left */}
            {buttonClicked && (
                <div
                    style={{
                        position: 'absolute',
                        bottom: '0',
                        left: '1rem',
                        color: 'black',
                        fontSize: '1rem',
                        marginTop: '30px',
                    }}
                >
                    <p>Ich liebe dich.</p>
                </div>
            )}

            {/* Conditional rendering for the welcome message */}
            {isVisible && (
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        background: `url(${backgroundImage})`, // Use the imported image
                        backgroundSize: 'cover',
                        backgroundPosition: 'center', // Center the image
                        backgroundRepeat: 'no-repeat',
                    }}
                >
                    <div
                        style={{
                            width: '100%',
                            maxWidth: '90%', // or any other max-width as per your design
                            padding: '5%',
                            background: 'rgba(255, 255, 255, 0.7)', // Semi-transparent white background for content
                            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                            borderRadius: '8px',
                            fontWeight: 'bold',
                            fontSize: '16px', // Adjust the font size for smaller screens
                            border: '4px solid #c00', // Red border to simulate a postcard
                            boxSizing: 'border-box',
                        }}
                    >
                        <p style={{ color: '#333', marginBottom: '10px' }}>
                            Hey Marie, <br />
                            ich wünsche dir wundervolle Weihnachten und ein tolles Fest mit allen, die dir am Herz
                            liegen!. <br />
                            <br />
                            Alternativ könnte auch Hey Grinsebacke hier stehen, vielleicht ist das ja ein Hinweis um
                            den Namen der Website zu verstehen.
                            <br />
                            Du fragst dich bestimmt was dich hier erwartet und bist gespannt auf den Inhalt oder
                            vielleicht den Sinn dieser Seite.
                            <br />
                            <br />
                            Egal ob genervt, gestresst oder in genereller Aufmunterungslaune, diese Seite wird dir
                            helfen!
                            <br />
                            <br />
                            Mit Klick auf den Button kannst du sofort loslegen!
                        </p>
                        <button
                            onClick={handleDismiss}
                            style={{
                                background: '#c00',
                                color: '#fff',
                                border: 'none',
                                fontSize: '14px', // Adjust the font size for smaller screens
                                padding: '10px',
                                borderRadius: '4px',
                                cursor: 'pointer',
                            }}
                        >
                            Ab zum Geschenk!
                        </button>
                    </div>
                </div>
            )}
        </>
    );
};

export default WelcomeMessage;
